import React from "react";
import TeamSection from "./TeamSection";

const Teams = ({refs}) => {
  const teamSections = [
    {
      bgColor: '#fff',
      logoSrc: '/image/sponsors/enrich-logo.png',
      teamTitle: "OUR MEN'S TEAM",
      teamSrc: '/image/ajfc/teamPics24/men.jpg',
      sponsor: 'Enrich',
      division: 'NCL Division 2',
      accolades: 'Forest Rec Champions 2021',
      coachName: 'Michael & Nicola',
      coachTitle: 'Manager',
      coachSrc: '/image/ajfc/team/img-1.png'
    },
    {
        bgColor: '#9c081c',
        logoSrc: '/image/ajfc/team/logo.png',
        teamTitle: "U15",
        teamSrc: null,
        // teamSrc: '/image/ajfc/teamPics/u15.png',
        sponsor: 'Aesthetics by Grace',
        division: 'U15 Notts Junior League',
        accolades: 'Forest Rec Champions 2021',
        coachName: 'Sefton',
        coachTitle: 'U15 Coach',
        coachSrc: '/image/ajfc/team/img-1.png' 
    },
    {
      bgColor: '#fff',
      logoSrc: '/image/ajfc/team/logo.png',
      teamTitle: "U14",
      imgAlignment: 'object-center',
      teamSrc: '/image/ajfc/teamPics24/u14.jpg',
      sponsor: 'Nexsis Communications',
      division: 'U13 YEL',
      accolades: 'Forest Rec Champions 2021',
      coachName: 'Jay',
      coachTitle: 'Head Coach',
      coachSrc: '/image/ajfc/team/img-1.png'
    },
      {
        bgColor: '#fff',
        logoSrc: '/image/ajfc/team/logo.png',
        teamTitle: "U13",
        teamSrc: '/image/ajfc/teamPics24/u13.jpg',
        imgAlignment: 'object-center',
        sponsor: 'Premiere Civils',
        division: 'U13 YEL',
        accolades: 'Forest Rec Champions 2021',
        coachName: 'Jay & Nawfal',
        coachTitle: 'Head Coach',
        coachSrc: '/image/ajfc/team/img-1.png'
      },
      {
        bgColor: '#9c081c',
        logoSrc: '/image/ajfc/team/logo.png',
        teamTitle: "U12's",
        teamSrc: '/image/ajfc/teamPics24/u12.jpg',
        sponsor: 'Ella Millett Solicitors',
        division: 'U12 YEL',
        accolades: 'Forest Rec Champions 2021',
        coachName: 'Luis',
        coachTitle: 'Head Coach',
        coachSrc: '/image/ajfc/team/img-1.png'
      },
      {
        bgColor: '#fff',
        logoSrc: '/image/sponsors/dg_logo.png',
        teamTitle: "U11's",
        // teamSrc: '/image/ajfc/teamPics/u11.png',
        sponsor: 'Love Mercy Hope',
        division: 'U11 YEL',
        accolades: 'Forest Rec Champions 2021',
        coachName: 'Tee',
        coachTitle: 'Head Coach',
        coachSrc: '/image/ajfc/team/img-1.png'
      },
      {
        bgColor: '#9c081c',
        logoSrc: '/image/ajfc/team/logo.png',
        teamTitle: "U10's SATURDAY",
        teamSrc: '/image/ajfc/teamPics24/u10.jpg',
        sponsor: 'Epic Hub',
        division: 'U10 YEL',
        accolades: 'Forest Rec Champions 2021',
        coachName: 'Rich',
        coachTitle: 'Head Coach',
        coachSrc: '/image/ajfc/team/img-1.png'
      },
      {
        bgColor: '#9c081c',
        logoSrc: '/image/ajfc/team/logo.png',
        teamTitle: "U10's SUNDAY",
        // teamSrc: '/image/ajfc/teamPics24/u10.jpg',
        sponsor: 'Epic Hub',
        division: 'U10 YEL',
        accolades: 'Forest Rec Champions 2021',
        coachName: 'Rich & Antony',
        coachTitle: 'Head Coach',
        coachSrc: '/image/ajfc/team/img-1.png'
      },
      {
        bgColor: '#fff',
        logoSrc: '/image/ajfc/team/logo.png',
        teamTitle: "U9's GOLD",
        // teamSrc: '/image/ajfc/team/img-2.png',
        sponsor: 'Premiere Civils',
        division: 'U9 YEL',
        accolades: 'Forest Rec Champions 2021',
        coachName: 'Andy',
        coachTitle: 'Head Coach',
        coachSrc: '/image/ajfc/team/img-1.png'
      },
      {
        bgColor: '#fff',
        logoSrc: '/image/ajfc/team/logo.png',
        teamTitle: "U9's RED",
        // teamSrc: '/image/ajfc/team/img-2.png',
        sponsor: 'Premiere Civils',
        division: 'U9 YEL',
        accolades: 'Forest Rec Champions 2021',
        coachName: 'Rueben',
        coachTitle: 'Head Coach',
        coachSrc: '/image/ajfc/team/img-1.png'
      },
      {
        bgColor: '#fff',
        logoSrc: '/image/ajfc/team/logo.png',
        teamTitle: "U9's BLACK",
        // teamSrc: '/image/ajfc/team/img-2.png',
        sponsor: 'Premiere Civils',
        division: 'U9 YEL',
        accolades: 'Forest Rec Champions 2021',
        coachName: 'Rueben',
        coachTitle: 'Head Coach',
        coachSrc: '/image/ajfc/team/img-1.png'
      },
      {
        bgColor: '#9c081c',
        logoSrc: '/image/ajfc/team/logo.png',
        teamTitle: "U8'S RED",
        // teamSrc: '/image/ajfc/teamPics/u8.png',
        sponsor: 'Lost Property',
        division: 'U8 YEL',
        accolades: 'Forest Rec Champions 2021',
        coachName: 'Rueben',
        coachTitle: 'Head Coach',
        coachSrc: '/image/ajfc/team/img-1.png'
      },
      {
        bgColor: '#9c081c',
        logoSrc: '/image/ajfc/team/logo.png',
        teamTitle: "U8'S GOLD",
        // teamSrc: '/image/ajfc/teamPics/u8.png',
        sponsor: 'Lost Property',
        division: 'U8 YEL',
        accolades: 'Forest Rec Champions 2021',
        coachName: 'Rich',
        coachTitle: 'Head Coach',
        coachSrc: '/image/ajfc/team/img-1.png'
      },
      {
        bgColor: '#9c081c',
        logoSrc: '/image/ajfc/team/logo.png',
        teamTitle: "U8'S WHITE",
        // teamSrc: '/image/ajfc/teamPics/u8.png',
        sponsor: 'Lost Property',
        division: 'U8 YEL',
        accolades: 'Forest Rec Champions 2021',
        coachName: 'Jo',
        coachTitle: 'Head Coach',
        coachSrc: '/image/ajfc/team/img-1.png'
      },
      {
        bgColor: '#9c081c',
        logoSrc: '/image/ajfc/team/logo.png',
        teamTitle: "U8'S BLACK",
        // teamSrc: '/image/ajfc/teamPics/u8.png',
        sponsor: 'Lost Property',
        division: 'U8 YEL',
        accolades: 'Forest Rec Champions 2021',
        coachName: 'Abdul',
        coachTitle: 'Head Coach',
        coachSrc: '/image/ajfc/team/img-1.png'
      },
      {
        bgColor: '#fff',
        logoSrc: '/image/ajfc/team/logo.png',
        teamTitle: "U7'S GOLD",
        // teamSrc: '/image/ajfc/teamPics/u7.png',
        sponsor: 'Wavey Kitchen',
        division: 'U7 YEL',
        accolades: 'Forest Rec Champions 2021',
        coachName: 'Donero',
        coachTitle: 'Head Coach',
        coachSrc: '/image/ajfc/team/img-1.png'
      },
      {
        bgColor: '#fff',
        logoSrc: '/image/ajfc/team/logo.png',
        teamTitle: "U7'S RED",
        // teamSrc: '/image/ajfc/teamPics/u7.png',
        sponsor: 'Wavey Kitchen',
        division: 'U7 YEL',
        accolades: 'Forest Rec Champions 2021',
        coachName: 'Donero',
        coachTitle: 'Head Coach',
        coachSrc: '/image/ajfc/team/img-1.png'
      }
    // Add more team sections as objects in this array
  ];

  return (
    <div className="py-[30px] flex flex-col gap-[20px]">
      {teamSections.map((section, index) => (
        <div className="" ref={refs.current[index]} key={index}>
          <TeamSection {...section} />
        </div>
      ))}
    </div>
  );
};

export default Teams;