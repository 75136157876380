import React from 'react';
import { MdOutlineKeyboardDoubleArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';

const TeamSection = ({
  bgColor,
  teamTitle,
  teamSrc,
  sponsor,
  division,
  coachName,
  coachTitle,
}) => {
  return (
    <main className={`py-5 md:py-12 px-5 md:px-10 shadow-xl bg-[${bgColor}]`}>
      {/* Title Section */}
      <section className="flex flex-col items-center justify-center gap-y-2">
        <h2 className="uppercase">{teamTitle}</h2>
      </section>

      {/* Content Section */}
      <section className="mt-6 max-w-[1100px] mx-auto">
        {/* Image Section (both mobile & desktop) */}
        {teamSrc ? (
          <img
            src={teamSrc}
            alt="Team"
            className="
              w-full
              object-cover
              max-h-[350px]
              md:max-h-[60vh]
              "
          />
        ) : (
          <div className="join-img relative w-full h-[150px] md:h-[300px] gradient mt-8">
            <img
              src="/image/home/joinus/bg-img.png"
              alt=""
              className="
                max-w-[500px]
                h-auto
                object-cover
                absolute
                top-0
                right-0
                z-[-1]
              "
            />
          </div>
        )}

        {/* Info & Join Button Section */}
        <div className="mt-4">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between flex-wrap gap-2 text-[25px] md:text-[30px]">
            <h3 className="uppercase text-black font-semibold">
              Sponsor : <span className="text-red ms-1">{sponsor}</span>
            </h3>
            <h3 className="uppercase text-black font-semibold">
              Divisions : <span className="text-red ms-1">{division}</span>
            </h3>
            <h3 className="uppercase text-black font-semibold">
              {coachTitle} : <span className="text-red ms-1">{coachName}</span>
            </h3>
          </div>

          <div className="mt-4">
            <Link
              to={{
                pathname: '/contact',
                state: { location: 'manchester', enquiry: 'player' },
              }}
            >
              <button
                className="
                  flex
                  items-center
                  justify-center
                  text-white
                  bg-red
                  font-medium
                  uppercase
                  transition
                  duration-300
                  hover:bg-[#9c081c]
                  focus:bg-[#9c081c]
                  px-5
                  py-2
                  w-full
                  text-lg
                  md:text-xl
                "
              >
                Join
                <MdOutlineKeyboardDoubleArrowRight className="ms-2" />
              </button>
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
};

export default TeamSection;
