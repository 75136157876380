import React from "react";

const SponsorsBody = () => {
    const partners = [
        "dylan_Rich.png",
        "balance.png",
        // "castle.png"
    ]
    const sponsors = [
        "energy-rich_v3.png",
        "airport_carrigies.png",
        "potato.png",
        "dgcars.png",
        "khatti.png",
        "wavey.png",
        "premiere.png",
        "saven.png",
        "lost.png",
        "funeral.png",
        "aesthetics.png",
        "loveMercyHope.png",
        "ellaMillet.png"
    ];

    return (
        <div className="sponsorBody w-[90%] m-auto py-[40px]">
         <span className=" red-badge bg-[#CFA855] text-[25px] w-full font-secondary">PARTNERS</span>
            {/* <h2 className="mb-5 font-[700] text-[35px]">
              welcome to the coolest sports academy
            </h2> */}
        <div className="grid grid-cols-3 md:grid-cols-4 gap-5 m-auto bg-[#F6F5F8] px-[10px] py-[30px] pr-[20px] mb-[40px]">
            {partners.map((partner, index) => (
                <img key={index} src={'image/sponsors/'+partner} alt={`Sponsor ${index}`} className="w-full h-auto" />
            ))}
        </div>
        <span className=" red-badge bg-red  text-[25px] w-full ">SPONSORS</span>
        <div className="grid grid-cols-4 md:grid-cols-5 gap-5 m-auto px-[10px] py-[30px] bg-[#F6F5F8]">
            {sponsors.map((sponsor, index) => (
                <img key={index} src={'image/sponsors/'+sponsor} alt={`Sponsor ${index}`} className="w-full h-auto" />
            ))}
        </div>
        </div>
        
    );
}

export default SponsorsBody;
