import React from "react";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";

const Gallery = () => {
  return (
    <main className="gallery-section  md:py-[80px] py-[60px] overflow-hidden">
      <section className="flex items-center justify-between flex-wrap md:px-[40px] px-[20px] mb-[30px]">
        <div className="gallery-title">
          <span className="red-badge mb-4">our gallery</span>
          <h2>gallery, memories we shared</h2>
        </div>
      </section>
          <div className="gallery-images-small lg:h-[430px] h-[118px] overflow-hidden lg:hidden">
            <div className="img3 object-fill"><img src="image/home/gallery/img-1.png" alt="" /></div>
            <div className="img1"><img src="image/home/gallery/img-2.png" alt="" /></div>
            <div>
                <div className="gallery-flex1">
                    <div className="img3"><img src="image/home/gallery/img-3.png" alt="" /></div>
                    <div className="img4"><img src="image/home/gallery/img-4.png" alt="" /></div>
                </div>
                <div className="img4 mt-[10px]"><img src="image/home/gallery/img-5.png" alt="" /></div>
            </div>
        </div>
        <section className="flex items-center justify-between flex-wrap md:px-[40px] px-[20px] mb-[30px]">
          <p className="text-gray text-[16px] md:max-w-[450px] my-6">
          In every snapshot, every captured cheer, and every victorious moment frozen in time, our gallery is more than just a collection of images. It's a vivid journey through the heart and soul of AJ Sport Nottingham. From the first tentative steps on the pitch to the confident strides of accomplished athletes, these images are a testament to the hard work, dedication, and spirit of our players and coaches.{" "}
          </p>
        <div className="gallery-btn">
        <Link to={'/contact'}>
          <button className="btn-primary">
            Learn more{" "}
            <MdOutlineKeyboardDoubleArrowRight className="ms-1 text-[20px]" />
          </button>
          </Link>
        </div>

        </section>

        <div className="gallery-images lg:h-[430px] h-[0] overflow-hidden">
            <div className="img3 object-fill"><img src="image/home/gallery/img-1.png" alt="" /></div>
            <div className="img1"><img src="image/home/gallery/img-2.png" alt="" /></div>
            <div>
                <div className="gallery-flex1">
                    <div className="img3"><img src="image/home/gallery/img-3.png" alt="" /></div>
                    <div className="img4"><img src="image/home/gallery/img-4.png" alt="" /></div>
                </div>
                <div className="img4 mt-[10px]"><img src="image/home/gallery/img-5.png" alt="" /></div>
            </div>
        </div>

    </main>
  );
};

export default Gallery;
