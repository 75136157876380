import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

const Joinus = () => {
  return (
    <div className="sm:pt-0 pt-[30px]  bg-[#F6F5F8] lg:grid grid-cols-8">
      <div className="content-section lg:py-[80px] md:ps-[40px] ps-[20px] lg:pe-0 md:pe-[40px] pe-[20px] col-span-4">
        <span className="red-badge mb-4">join us now</span>
        <h2>
          talk with your feet, <br />
          play with your heart
        </h2>
        <div className="max-w-[500px] my-5">
          <p className="text-justify text-gray text-[16px]">
          At AJ Sport Nottingham, we believe football is more than just a game—it's a way of life. Here, every dribble, every pass, and every goal stands for our dedication, passion, and love for football. We are not just developing players; we're nurturing future leaders, teammates, and passionate individuals who play with their hearts on their sleeves.
          </p>
          <p className="text-justify text-gray text-[16px] mt-3">
          With expert coaching, first-class facilities, and a pathway to professional play, AJ Sport is the perfect place to grow not just as a player, but as a person. Here, every practice session is an opportunity to improve, every game a chance to shine, and every interaction a moment to build lifelong friendships.
          </p>
        </div>
        <Link to={'/contact'}>
        <button type="button" className="btn-primary-outline mt-r">
          JOIN US{" "}
          <MdOutlineKeyboardDoubleArrowRight className="ms-3 text-[20px]" />
        </button>
        </Link>
      </div>
      <div className="join-img gradient relative col-span-4 w-full lg:h-auto h-[400px] lg:mt-0 mt-8">
            <img src="/image/home/joinus/bg-img.png" className="max-w-[500px] h-auto object-cover  absolute top-0 right-0 z-[-1]" alt="" />
            <img
            src="/image/home/contact/ContactHeader2.png"
            className="max-w-[350px] lg:h-auto h-[400px] object-cover absolute bottom-0 left-[50%] translate-x-[-50%]"
            alt=""
          />
            {/* <img src="/image/home/joinus/join-img.png" className="max-w-[350px] lg:h-auto h-[400px] object-cover absolute bottom-0 left-[50%] translate-x-[-50%]" alt="" /> */}
      </div>
    </div>
  );
};

// style={{background : "url('/image/home/joinus/bg-img.png') no-repeat right"}}

export default Joinus;
