import React from "react";

const Category = ({scrollToTeam}) => {
  const content = [
    {name:"MEN", index:0},
    {name:"U15", index:1},
    {name:"U14", index:2},
    {name:"U13", index:3},
    {name:"U11", index:4},
    {name:"U10", index:6},
    {name:"U9", index:8},
    {name:"U8", index:11},
    {name:"U7", index:15},
  ];

  return (
    <main className="py-[60px] md:px-[40px] px-[20px] bg-[#F6F5F8] sm:block">
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 md:gap-10 gap-6">
        {content.map((element, index) => {
          
          return (
            <button
              onClick={() =>
                scrollToTeam(element.index)
              }
              key={index}
              className="max-w-[250px] h-[75px] bg-red text-white transition duration-300 hover:bg-[#9c081c] hover:text-white focus:bg-[#9c081c] focus:text-white flex items-center justify-center text-[30px] font-semibold"
            >
              {element.name}
            </button>
          );
        })}
      </div>
    </main>
  );
};

export default Category;
