import React from "react";
import {useState, useEffect} from "react";
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";

const Contact = ({ location: initialLocation, enquiry: initialEnquiry }) => {
  const [location, setLocation] = useState(initialLocation);
  const [enquiry, setEnquiry] = useState('general');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  // useEffect(() => {
  //   setLocation(initialLocation)
  // }, [])

  useEffect(() => {
    // Perform actions based on location
    // For example, setting form data based on initial prop or location change
    setFormData({
      ...formData,
      location: location
    });
  }, [location])

  // Effect to update form data based on enquiry prop change
  useEffect(() => {
    setFormData(formData => ({
      ...formData,
      enquiry: enquiry
    }));
  }, [enquiry]);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Endpoint URL (replace with your actual endpoint)
    // const endpoint = 'http://localhost:3001/form';
    // const endpoint = 'http://4.250.58.231:3001/form'
    const endpoint = 'https://netreach.app:3001/form'
    // POST request using fetch
    fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
    })
    .then(response =>  {
        console.log('Success:', response);
        alert("Request Sent")
        setShowSuccessAlert(true); // Show the success alert
        setTimeout(() => setShowSuccessAlert(false), 3000); // Hide alert after 3 seconds

        // Handle success (e.g., showing a success message)
        alert('message sent')
        setFormData({
          location: location,
          enquiry: enquiry,
          name: '',
          email: '',
          phone: '',
          message: '',
          position: '',
          DOB: '',
          experience: '',
      })
    })
    .catch((error) => {
        console.error('Error:', error);
        // Handle errors (e.g., showing an error message)
    });
}


  const [formData, setFormData] = useState({
    location: location,
    enquiry: enquiry,
    name: '',
    email: '',
    phone: '',
    message: '',
    position: '',
    DOB: '',
    experience: '',
});

const handleChange = (event) => {
  const { name, value } = event.target;
  if (name === "enquiry") {
    setEnquiry(value); // Directly set the enquiry state for enquiry dropdown changes
  }

  setFormData({
      ...formData,
      [name]: value
  });
}

// const handleLocationChange = (location) => {
//   setFormData({
//       ...formData,
//       location: location
//   });
//   if (location != "Nottingham") {
//       const enquiry = document.getElementById("enquiry")
//       enquiry.value = "General"
//   }
// }

const handleLocationChange = (newLocation) => {
  setLocation(newLocation); // This will trigger useEffect
}

  // Function to determine image based on location
  const getLocationImage = () => {
    switch (location) {
      case 'london':
        return "/image/home/contact/contact-london.png";
      case 'manchester':
        return "/image/home/contact/contact-manchester.png";
      case 'nottingham':
      default:
        return "/image/home/contact/nicola_welfareOfficer.jpg";
    }
  };

  const renderAdditionalFields = () => {
    switch (formData.enquiry) {
        case 'player':
            return (
                <React.Fragment>
                    <div className="form-group">
                        <div className="label-input-container">
                            {/* <label htmlFor="position" className="form-label">Player Position</label> */}
                            <select name="position" id="position" value={formData.position} onChange={handleChange} 
                              className="lg:max-w-[450px] w-full h-[40px] bg-white text-black px-3 text-[14px] focus:outline-none"
                              >
                                <option value="" disabled selected>Position</option>
                                <option value="Forward">Forward</option>
                                <option value="Midfielder">Midfielder</option>
                                <option value="Defender">Defender</option>
                                <option value="Goalkeeper">Goalkeeper</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="label-input-container">
                            {/* <label htmlFor="DOB" className="form-label">Date of Birth</label> */}
                            {/* <label htmlFor="DOB" className="form-label" style={{ display: "block", marginBottom: "8px" }}>Date of Birth</label> */}

                            <input type="date" name="DOB" id="DOB" value={formData.DOB} onChange={handleChange} 
                            className="lg:max-w-[450px] w-full h-[40px] bg-white text-black px-3 text-[14px] focus:outline-none"
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="label-input-container">
                            {/* <label htmlFor="experience" className="form-label">Experience Level</label> */}
                            {/* <select name="experience" id="experience" value={formData.experience} onChange={handleChange} className="form-input"> */}
                            <select 
                              name="experience" id="experience" value={formData.experience} onChange={handleChange}
                              className="lg:max-w-[450px] w-full h-[40px] bg-white text-black px-3 text-[14px] focus:outline-none"
                            >
                                <option value="" disabled selected>Experience Level</option>
                                <option value="Advanced">Advanced</option>
                                <option value="Intermediate">Intermediate</option>
                                <option value="Beginner">Beginner</option>
                            </select>
                        </div>
                    </div>
                </React.Fragment>
            );
        case 'booking':
            return (
                <React.Fragment>
                    <div className="form-group">
                        <div className="label-input-container">
                            <label htmlFor="reservationDate" className="form-label">Reservation Date</label>
                            <input type="date" name="reservationDate" id="reservationDate" value={formData.reservationDate} onChange={handleChange} className="form-input" />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="label-input-container">
                            <label htmlFor="reservationTime" className="form-label">Reservation Time</label>
                            <input type="time" name="reservationTime" id="reservationTime" value={formData.reservationTime} onChange={handleChange} className="form-input" />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="label-input-container">
                            <label htmlFor="eventType" className="form-label">Type of Event</label>
                            <input type="text" name="eventType" id="eventType" value={formData.eventType} onChange={handleChange} className="form-input" />
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="label-input-container">
                            <label htmlFor="numberOfAttendees" className="form-label">Number of Attendees</label>
                            <input type="number" name="numberOfAttendees" id="numberOfAttendees" value={formData.numberOfAttendees} onChange={handleChange} className="form-input" />
                        </div>
                    </div>
                </React.Fragment>
            );
        default:
            return null;
    }
};

  return (
    <main 
      // className="contact-section relative lg:grid grid-cols-2 mt-[40px]"
      className="contact-section relative flex"
    >
        {/* <img src="/image/home/contact/contact-bg.png" className="absolute lg:block hidden z-[-1] max-w-[780px] h-[600px]" alt="" /> */}
      <section
        className="md:w-1/2 w-full h-[120vh] contact-content-section py-[60px] md:ps-[40px] ps-[20px] px-[20px] bg-cover bg-center bg-no-repeat bg-[#F6F5F8]"
      >
        <span className="red-badge mb-3">enquire here</span>
        <h2>contact us</h2>
        <div className="w-full sm:h-[50px] flex items-center flex-wrap sm:gap-y-1 gap-y-2 mt-6 ">
          <button 
            key={'london'}
            // className="sm:w-[140px] w-[110px] h-[40px] font-[600] text-white z-[10] contact-btn bg-red uppercase text-[14px] font-primary transition duration-300 hover:bg-red hover:text-white"
            className={`${location === 'london' ? "text-white bg-red" : "text-red bg-white"} sm:w-[140px] w-[110px] h-[40px] font-[600] z-[10] contact-btn uppercase text-[14px] font-primary transition duration-300 hover:bg-red hover:text-white`}

            onClick={() => setLocation('london')}
          >
            london
          </button>
          <button 
            key={'manchester'}
            // className="sm:w-[140px] w-[110px] h-[40px] font-[600] text-white z-[10] contact-btn bg-red uppercase text-[14px] font-primary transition duration-300 hover:bg-red hover:text-white"
            className={`${location === 'manchester' ? "text-white bg-red" : "text-red bg-white"} sm:w-[140px] w-[110px] h-[40px] font-[600] z-[10] contact-btn uppercase text-[14px] font-primary transition duration-300 hover:bg-red hover:text-white`}

            onClick={() => setLocation('manchester')}
          >            
            manchester
          </button>
          <button 
            key={'nottingham'}
            // className="sm:w-[140px] w-[110px] h-[40px] font-[600] text-white z-[10] contact-btn bg-red uppercase text-[14px] font-primary transition duration-300 hover:bg-red hover:text-white"
            className={`${location === 'nottingham' ? "text-white bg-red" : "text-red bg-white"} sm:w-[140px] w-[110px] h-[40px] font-[600] z-[10] contact-btn uppercase text-[14px] font-primary transition duration-300 hover:bg-red hover:text-white`}

            onClick={() => setLocation('nottingham')}
          >            nottingham
          </button>
        </div>
        <form 
          onSubmit={handleSubmit}
          className="sm:mt-5 mt-10 w-full flex flex-col gap-y-4"
        >
          <div>
            <input
              type="text"
              name="name"
              className="lg:max-w-[450px] w-full h-[40px] bg-white text-black px-3 text-[14px] focus:outline-none"
              placeholder="NAME / ORGANIZATION"
              id="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              name="email"
              className="lg:max-w-[450px] w-full h-[40px] bg-white text-black px-3 text-[14px] focus:outline-none"
              placeholder="YOUR EMAIL"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div>
            <input
              type="text"
              name="phone"
              className="lg:max-w-[450px] w-full h-[40px] bg-white text-black px-3 text-[14px] focus:outline-none"
              placeholder="YOUR NUMBER"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div>
            <select 
              name="enquiry" id="enquiry" value={formData.enquiry} onChange={handleChange}
              className="lg:max-w-[450px] w-full h-[40px] bg-white text-black px-3 text-[14px] focus:outline-none"
            >
              {/* <option value="" defaultChecked>
                ENQUIRE TYPE
              </option> */}
              <option value="general">General</option>
              <option value="player">Player</option>
              {/* <option value="booking">Booking</option> */}
            </select>
          </div>
          {renderAdditionalFields()}
          <textarea 
            className=" lg:max-w-[450px] w-full h-[150px] resize-none bg-white text-black px-3 pt-2 focus:outline-none" 
            placeholder="MESSAGE"
            name="message"
            id="message"
            value={formData.message}
            onChange={handleChange}
          >

          </textarea>
            <button className="btn-primary lg:w-[450px] w-full flex items-center justify-center">
            Submit{" "}
            <MdOutlineKeyboardDoubleArrowRight className="ms-1 text-[20px]" />
          </button>
        </form>
      </section>
      <section className="md:relative md:h-[120vh] h-full overflow-hidden w-full hidden">
      {
        (location == 'nottingham' || location == null) &&
      <div className="z-50 text-[100px] relative translate-y-[500px]">
  <h2 className="z-50 text-[80px] relative bg-white w-max px-5 py-3">Nicola</h2>
  <h2 className="z-50 relative text-white bg-red w-max px-5 py-3">Welfare Officer</h2>

      </div>
      }
      
  <img
    src={getLocationImage()}
    className="z-20 absolute top-0 w-full object-cover h-[120vh]"
    alt=""
  />
</section>

    </main>
  );
};

export default Contact;
